<template>
  <div>
    <b-sidebar id="sidebar-add-new-event" sidebar-class="sidebar-lg" :visible="isEventHandlerSidebarActive"
      bg-variant="white" shadow backdrop no-header right
      @change="(val) => $emit('update:is-event-handler-sidebar-active', val)">
      <template #default="{ hide }">
        <div v-if="!eventLocal.id">

          <!-- Header -->
          <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
            <h5 class="mb-0">
              {{ eventLocal.id ? 'Update' : 'Add' }} Booking
            </h5>
            <div>
              <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
            </div>
          </div>

          <!-- Body -->
          <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
            <!-- Form -->
            <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
              <b-row>
                <b-col md="6">
                  <!-- Start Date -->
                  <validation-provider #default="validationContext" name="Start Date" rules="required">

                    <b-form-group label="Start Date" label-for="start-date"
                      :state="getValidationState(validationContext)">
                      <date-time-picker v-model="eventLocal.start" />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="6">

                  <!-- End Date -->
                  <validation-provider #default="validationContext" name="End Date" rules="required">

                    <b-form-group label="End Date" label-for="end-date" :state="getValidationState(validationContext)">
                      <date-time-picker v-model="eventLocal.end" :minDate="eventLocal.start" />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>

                </b-col>
              </b-row>

              <!-- Assistant -->
              <validation-provider #default="validationContext" name="Assistant" rules="required">
                <b-form-group label="Assistant" label-for="assistant" :state="getValidationState(validationContext)">

                  <v-select id="assistant" v-model="eventLocal.extendedProps.assistant" :clearable="false" label="text"
                    :options="users" :reduce="option => option.value" :state="getValidationState(validationContext)" />

                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <div class="d-flex mt-2">
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" type="submit">
                  {{ eventLocal.id ? 'Update slot' : 'Book slot' }}
                </b-button>
              </div>
            </b-form>
          </validation-observer>
        </div>


        <div v-if="eventLocal.id && eventLocal.extendedProps.status == -1">

          <!-- Header -->
          <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
            <h5 class="mb-0">
              {{ eventLocal.id ? 'Update' : 'Add' }} Booking
            </h5>
            <div>
              <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
            </div>
          </div>

          <!-- Body -->
          <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
            <!-- Form -->
            <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
              <b-row>
                <b-col md="6">
                  <!-- Start Date -->
                  <validation-provider #default="validationContext" name="Start Date" rules="required">

                    <b-form-group label="Start Date" label-for="start-date"
                      :state="getValidationState(validationContext)">
                      <date-time-picker v-model="eventLocal.start" />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="6">

                  <!-- End Date -->
                  <validation-provider #default="validationContext" name="End Date" rules="required">

                    <b-form-group label="End Date" label-for="end-date" :state="getValidationState(validationContext)">
                      <date-time-picker v-model="eventLocal.end" :minDate="eventLocal.start" />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>

                </b-col>
              </b-row>
              <!-- Assistant -->
              <validation-provider #default="validationContext" name="Assistant" rules="required">
                <b-form-group label="Assistant" label-for="assistant" :state="getValidationState(validationContext)">

                  <v-select id="assistant" v-model="eventLocal.extendedProps.assistant" :clearable="false" label="text"
                    :options="users" :reduce="option => option.value" :state="getValidationState(validationContext)" />

                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>


              <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="danger" v-b-modal.modal-delete>
                Delete
              </b-button>

              <div class="d-flex mt-2">
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" type="submit">
                  {{ eventLocal.id ? 'Update slot' : 'Book slot' }}
                </b-button>
              </div>
            </b-form>
          </validation-observer>
        </div>

        <div v-if="eventLocal.id && eventLocal.extendedProps.status == 1">
          <b-overlay :show="loading || loadingLogs" rounded="sm">
            <!-- Header -->
            <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
              <h5 class="mb-0">
                Activity Log
              </h5>
              <div>
                <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
              </div>
            </div>
            <div class="p-2 mt-8">
              <b-row>
                <b-col md="6">
                  <b-row>
                    <b-col md="1">
                      <feather-icon icon="CalendarIcon" class="icon" size="24" />
                    </b-col>
                    <b-col>
                      <span class="d-inline-block ml-1">On {{ date }}</span>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col md="6">
                  <b-row>
                    <b-col md="1">
                      <feather-icon icon="ClockIcon" class="icon" size="24" />
                    </b-col>
                    <b-col>
                      <span class="d-inline-block ml-1">Total time spent:<br> {{ time }} hrs</span>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </div>

            <div class="divider my-2">
              <div class="divider-text">
                Task Logs
              </div>
            </div>
            <div class="m-2">
              <div v-for="log in logs" :key="log.id" class="log-container my-1">
                <div class="header">
                  <b-img :src="require('@/assets/images/doctor.png')" class="icon" />
                  <span class="name" style="margin-right: 16px;">{{ log.attributes.doctor }}</span>
                  <b-img :src="require('@/assets/images/patient.png')" class="icon" />
                  <span class="name" style="flex: 1">{{ log.attributes.patient }}</span>
                </div>
                <div class="p-1">
                  <p class="comment">{{ log.attributes.comments }}</p>
                  <p class="updatedAt">{{ log.updatedAt }}</p>
                </div>
              </div>
            </div>
          </b-overlay>
        </div>
        <div v-if="eventLocal.id && eventLocal.extendedProps.status == 0">
          <b-overlay :show="loading || loadingLogs" rounded="sm">
            <!-- Header -->
            <div class="content-sidebar-header px-2 py-1">
              <h5 class="mb-0">
                Assignment Rejected
              </h5>
              <p>
                {{ eventLocal.extendedProps.rejectReason }}
              </p>
            </div>
          </b-overlay>

          <div class="p-2">
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="danger" v-b-modal.modal-delete>
              Delete
            </b-button>
          </div>
        </div>
      </template>
    </b-sidebar>

    <b-modal id="modal-reason" ref="my-modal" title="Submit Your Name" ok-title="Submit"
      cancel-variant="outline-secondary" @show="resetModal" @hidden="resetModal" @ok="handleOk">
      <form ref="form" @submit.stop.prevent="handleReasonSubmit">
        <b-form-group :state="reasonState" label="Please provide a rejection reason: *" label-for="name-input"
          invalid-feedback="Reason is required">
          <b-form-textarea id="name-input" v-model="reason" :state="reasonState" required />
        </b-form-group>
      </form>
    </b-modal>

    <b-modal id="modal-delete" ref="my-modal-delete" title="Confirm" ok-title="Yes" cancel-variant="outline-secondary"
      @show="resetModal" @hidden="resetModal" @ok="deleteEvent">
      <h4>Are you sure to delete booking?</h4>
    </b-modal>
  </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormCheckbox, BAvatar, BFormTextarea, BButton, BFormInvalidFeedback, BRow, BCol, BModal, BOverlay, BImg
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email, url } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ref, toRefs } from '@vue/composition-api'
import useCalendarEventHandler from './useCalendarEventHandler'
import Parse from "parse/dist/parse.min.js";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import moment from 'moment'
import DateTimePicker from "@core/components/DateTimePicker.vue";
import { convertMinToHr } from "@core/utils/utils";

export default {
  components: {
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormTextarea,
    BRow,
    BCol,
    BAvatar,
    BModal,
    vSelect,
    flatPickr,
    ValidationProvider,
    BFormInvalidFeedback,
    ValidationObserver,
    BOverlay,
    BImg,
    DateTimePicker
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isEventHandlerSidebarActive',
    event: 'update:is-event-handler-sidebar-active',
  },
  props: {
    isEventHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
    event: {
      type: Object,
      required: true,
    },
    clearEventData: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      required,
      email,
      url,
      users: [],
      clients: [],
      reason: '',
      reasonState: null,
      time: "00:00",
      date: "",
      logs: [],
      loading: false,
      loadingLogs: false,
    }
  },
  created() {
    this.fetchClients()
    this.fetchUsers()
  },
  methods: {
    fetchClients() {
      const Client = Parse.Object.extend("Client");
      const query = new Parse.Query(Client);
      query
        .find()
        .then((results) => {
          this.clients = results.map(client => ({
            value: client.id,
            text: `${client.attributes.name} ${client.attributes.department}`
          }));
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Error fetching client list",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },
    fetchUsers() {
      const query = new Parse.Query(Parse.Object.extend("User"));
      const Role = Parse.Object.extend("_Role");
      const innerQuery = new Parse.Query(Role);
      innerQuery.equalTo("name", "assistant")

      query.matchesQuery("role", innerQuery);

      const clientId = localStorage.getItem("clientId");
      const Client = Parse.Object.extend("Client");
      const clientObj = new Client();
      clientObj.id = clientId;
      query.equalTo("clients", clientObj);

      query
        .find()
        .then((results) => {
          this.users = results.map(user => ({
            value: user.id,
            text: `${user.attributes.firstName} ${user.attributes.lastName}`
          }));

          this.users.unshift({
            value: "-1",
            text: 'Any Assistant'
          })
        })
        .catch((ex) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Error fetching user list",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },

    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()
      this.reasonState = valid
      return valid
    },
    resetModal() {
      this.reason = ''
      this.reasonState = null
    },
    handleOk() {
      this.handleReasonSubmit()
    },
    handleReasonSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return
      }

      this.eventLocal.extendedProps.rejectReason = this.reason
      this.eventLocal.extendedProps.status = 0

      this.onSubmit()

    },
    accept() {
      this.eventLocal.extendedProps.status = 1
      this.onSubmit()
    },
    fetchBookingDetails() {
      this.loading = true

      const Booking = Parse.Object.extend("Booking");
      const query = new Parse.Query(Booking);
      query.equalTo("objectId", this.eventLocal.id)
      query
        .find()
        .then((results) => {
          if (results[0]) {
            this.time = results[0].attributes.time ?? "00:00"
            this.date = `${moment(results[0].attributes.start).format("dddd DD MM YYYY H:mm")} ~ ${moment(results[0].attributes.end).format("H:mm")}`
            this.loading = false
          }
        })
        .catch((ex) => {
          this.loading = false
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Error fetching booking details",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },
    fetchLogs() {
      this.loadingLogs = true;
      const Log = Parse.Object.extend("Log");
      const Booking = Parse.Object.extend("Booking");

      const query = new Parse.Query(Log);

      const booking = new Booking();
      booking.set("objectId", this.eventLocal.id);

      query.equalTo("booking", booking)

      query
        .find()
        .then((results) => {
          this.logs = results;
          this.loadingLogs = false;
        })
        .catch((ex) => {
          this.loadingLogs = false;
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Error fetching logs",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },
    onChangeDate() {
      if (this.eventLocal.start && this.eventLocal.end) {
      }
    },
    deleteEvent() {
      this.deleteBooking()
    }
  },
  setup(props, { emit }) {
    /*
     ? This is handled quite differently in SFC due to deadlock of `useFormValidation` and this composition function.
     ? If we don't handle it the way it is being handled then either of two composition function used by this SFC get undefined as one of it's argument.
     * The Trick:

     * We created reactive property `clearFormData` and set to null so we can get `resetEventLocal` from `useCalendarEventHandler` composition function.
     * Once we get `resetEventLocal` function which is required by `useFormValidation` we will pass it to `useFormValidation` and in return we will get `clearForm` function which shall be original value of `clearFormData`.
     * Later we just assign `clearForm` to `clearFormData` and can resolve the deadlock. 😎

     ? Behind The Scene
     ? When we passed it to `useCalendarEventHandler` for first time it will be null but right after it we are getting correct value (which is `clearForm`) and assigning that correct value.
     ? As `clearFormData` is reactive it is being changed from `null` to corrent value and thanks to reactivity it is also update in `useCalendarEventHandler` composition function and it is getting correct value in second time and can work w/o any issues.
    */
    const clearFormData = ref(null)
    const toast = useToast()

    const {
      eventLocal,
      resetEventLocal,
      calendarOptions,
      deleteBooking,

      // UI
      onSubmit,
      guestsOptions,
    } = useCalendarEventHandler(toRefs(props), clearFormData, emit)

    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    } = formValidation(resetEventLocal, props.clearEventData)

    clearFormData.value = clearForm

    return {
      // Add New Event
      eventLocal,
      calendarOptions,
      onSubmit,
      guestsOptions,
      deleteBooking,

      // Form Validation
      resetForm,
      refFormObserver,
      getValidationState,
      toast,
      convertMinToHr
    }
  },
  watch: {
    eventLocal: function (newVal, oldVal) {
      if (newVal.id) {
        this.fetchBookingDetails()
        this.fetchLogs()
      }
      else {
        this.time = "00:00"
      }

    },
    'eventLocal.start': function (newVal, oldVal) {
      this.onChangeDate()
    },
    'eventLocal.end': function (newVal, oldVal) {
      this.onChangeDate()
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.icon {
  color: #7367F0;
  height: 24px;
}

.button-icon {
  margin-right: 4px;
}

.log-container {
  border-radius: 8px;
  border: 1px solid #EDEDFE;

  .header {
    padding: 6px;
    display: flex;
    flex-direction: row;
    border-bottom: solid 1px #EDEDFE;

    .cursor-pointer {
      color: #484848;
      margin: 3px;
    }
  }

  img {
    margin-left: 4px;
    margin-right: 4px;
  }

  .name {
    color: #7367F0;
    margin-top: 2px;
  }

  .comment {
    font-size: 14px;
  }

  .updatedAt {
    font-style: italic;
    margin-bottom: 0 !important;
    font-size: 10px;
  }

  .time {
    font-size: 12px;
  }

}
</style>
